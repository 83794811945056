export default {
  already_account: 'You have already an account?',
  login: 'Register',
  signin: 'Sign in',
  'form.email': 'Email',
  'form.tel': 'Phone number',
  'form.password': 'Password',
  'form.password.contentError':
    'Password would contain at least eight characters, one letter uppercase, one  letter lowercase, one  number and one special character',
  'form.confirmPassword': 'Confirm Password',
  'form.button.next': 'Next',
  'dialogue.title': 'VALIDATION CODE',
  'dialogue.content':
    "Thanks to see your mail address and insert code sending.If not please ",
  'dialogue.form.valid': 'Confirm',
  'dialogue.button.send': 'Re-send',
  'dialogue.message.send': "Thanks to see your email address to see new code",
  'dialogue.message.codeError': "Thanks to insert the right code",
  'dialogue.form.cancel': 'Cancel',
  'account.create': 'Account create',
  'account.empty': 'Email and password field empty',
  'account.email.empty': 'Email field empty',
  'account.password.empty': 'Password field empty',
  'account.password.notMatch': 'Password not match',
  'account.data.notValid': 'Invalid email or password',
  'account.code.notValid': 'Invalid code',
  'account.confirm': 'Compte confirmed with success',
  'account.code.send': "Thanks to see your email and insert the right code ",
  'account.data.password.notValid': `The password does not match the prerequisite`,
  'account.data.email.notValid': 'Invalid email address',
  'account.phoneNumber.invalid': `Invalid phone number`,
  'stepone.authentication.failed': 'Email already used',
  'stepone.authentication.password.failed':
    "Thanks to see your email and insert the right password ",
  'error.invalidPassword': 'Invalid password',
  'pwd.weak': 'Weak',
  'pwd.fair': 'Fair',
  'pwd.good': 'Good',
  'pwd.strong': 'Strong',
  'pwd.meter': 'Password level :',
};
