export default {
  'deposit.title': 'Dépôt de capital avec Sapheer',
  'deposit.description': ` vous permet de déposer le capital de votre entreprise pour finaliser l'immatriculation de votre société`,
  'deposit.first.subtitle': 'Documents nécessaires',
  'deposit.first.paragraph': 'Vous devrez fournir pour chaque associé :',
  'deposit.first.listItem1': "Une pièce d'identité de bonne qualité ",
  'deposit.first.listItem2': 'Un justificatif de domicile de bonne qualité ',
  'deposit.second.subtitle': 'Statut de votre entreprise',
  'deposit.second.paragraph': `Vous n'avez pas besoin de vos statuts définitifs pour poursuivre votre inscription. La liste des
  associés doit cependant être définitive.`,
  'deposit.first.link': '(en savoir plus)',
  'deposit.second.link':
    'les mentions spécifiques au dépôt de capital avec Sapheer',
  'deposit.status.title': 'Modification des statuts de votre société',
  'deposit.status.p1':
    'Afin que nous puissions fournir votre attestation de dépôt des fonds, il est nécessaire que vous ajoutiez les mentions suivantes aux statuts de votre société :',
  'deposit.status.p2': 'Dans l\'article "Apports", le paragraphe suivant :',
  'deposit.status.p3':
    "\"Les fonds correspondants aux apports en numéraire ont été déposés par Sapheer, société SPARK SAS, dûment mandatée à cet effet par chacun des associés, sur le compte ouvert au nom de la Société en formation auprès de Etude de Maître Quentin FOUREZ - Notaire au 1 Place Maréchal Gallieni - 27500 PONT-AUDEMER, ainsi qu'il résulte du certificat établi par le notaire dépositaire des fonds, sur présentation notamment de l'état des souscriptions mentionnant la somme versée par les associés. L'état des souscriptions joint aux présents statuts est certifié sincère et véritable par le représentant légal de la société.\"",
  'deposit.status.p4':
    "Dans l'annexe mentionnant l'état des actes accomplis au nom de la société en formation avant la signature des statuts :",
  'deposit.status.p5': "Dépôt du capital social auprès d'une étude notariale.",
  'deposit.status.p6':
    "Ouverture d'un compte de transit à leurs noms auprès de SPARK SAS (Spaheer), établissement de paiement agréé auprès de l'ACPR",
  'deposit.follow.instruction': `Suivez les instructions, c'est très simple !`,
  'deposit.step.one': `Étape 1`,
  'deposit.step.two': `Étape 2`,
  'deposit.step.three': `Étape 3`,
  'step.one.text': `Les fonds correspondants aux apports en numéraire ont été déposés par Sapheer, société SPARK SAS, dûment mandatée à cet effet par chacun des associés, sur le compte ouvert au nom de la Société en formation auprès de Etude de Maître Quentin FOUREZ - Notaire au 1 Place Maréchal Gallieni - 27500 PONT-AUDEMER, ainsi qu’il résulte du certificat établi par le notaire dépositaire des fonds, sur présentation notamment de l’état des souscriptions mentionnant la somme versée par les associés. L’état des souscriptions joint aux présents statuts est certifié sincère et véritable par le représentant légal de la société.`,
  'step.two.text': `Dépôt du capital social auprès d’une étude notariale. Ouverture d’un compte de transit à leurs noms auprès de SPARK SAS (Sapheer), établissement de paiement agréé auprès de l’ACPR.`,
  'deposit.step.one.first.paragraph': `Insérer ce `,
  'deposit.step.one.link': `texte `,
  'deposit.step.one.second.paragraph': `dans vos statuts à l'article  “Apports“ en faisant copier/coller.`,
  'deposit.step.two.first.paragraph': `Insérer ce `,
  'deposit.step.two.link': `texte `,
  'deposit.step.two.second.paragraph': `dans l'annexe mentionnant l'état des actes accomplis au nom de la société en formation avant la signature 
  des statuts en faisant copier/coller.`,
  'deposit.step.three.paragraph': `Vous devez fournir pour chaque associé, une pièce d'identité et un justificatif de domicile pour la 
  suite de la souscription.`,
  'step.one.dialog.title': `Texte destiné à l'article "Apports"`,
  'step.two.dialog.title': `Texte destiné à l'état des actes accomplis`
};
