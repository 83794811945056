import gql from 'graphql-tag';


const COMPANY_DOCUMENT_FRAGMENT = gql`
  fragment CompanyDocsInfo on CompanyDocuments {
    id
    uuid
    filename
    filetype
    documentType{
      id 
      name
      category
    }
    treezorDocumentId
    label
  }
`

const COMPANY_FRAGEMENT = gql`
  fragment CompanyInfo on Company {
    id
    legalName
    legalForm
    legalFormLabel
    legalRegistrationNumber
    legalShareCapital
    legalRegistrationDate
    legalSector
    legalNumberOfEmployeeRange
    isDepositCapitalFeePaid
    legalNetIncomeRange
    legalAnnualTurnOver
    numeroTva
    address
    postCode
    city
    companyDocuments{
     ...CompanyDocsInfo
    }
    persons {
      name
      firstName
      birthday
      address
    }
  }
  ${COMPANY_DOCUMENT_FRAGMENT}
`;

const SEARCH_COMPANY_FRAGMENT = gql`
  fragment SearchCompanyInfo on SearchCompanyInfo {
    legalName
    legalRegistrationNumber
    address
    tvaNumber
  }
`;

export { COMPANY_FRAGEMENT, SEARCH_COMPANY_FRAGMENT, COMPANY_DOCUMENT_FRAGMENT };
