export default {
  'stepFive.subtitle': 'Informations légales',
  'stepFive.first.radio': 'Directeur',
  'stepFive.second.radio': 'Mandataire',
  'stepFive.form.gender': 'Genre',
  'stepFive.form.name': 'Nom',
  'stepFive.form.surname': 'Prénom',
  'stepFive.form.nationality': 'Nationalité',
  'stepFive.form.citizenShip': 'Citoyenneté',
  'stepFive.form.dateOfBirth': 'Date de naissance',
  'stepFive.form.countryOfBirth': 'Pays de naissance',
  'stepFive.form.country': 'Pays',
  'stepFive.form.zipCode': 'Code postal',
  'stepFive.form.cityOfBirth': 'Ville de naissance',
  'stepFive.form.city': 'Ville',
  'stepFive.form.identityType': "Type de pièce d'identité",
  'stepFive.form.identityType2': 'Vigilance complémentaire',
  'stepFive.form.identityProof': "Justificatif de pièce d'identité",
  'stepFive.form.personalAddressTitle': 'Adresse personnelle',
  'stepFive.form.personalAddress': 'Adresse perso',
  'stepFive.form.companyAddress': 'Adresse',
  'stepFive.form.addressType': 'Type de justificatif de domiciliation',
  'stepFive.form.addressProof': 'Justificatif de domicile',
  'stepFive.form.checkbox': `Je certifie sur l'honneur l'exactitude des informations fournies.`,
  'stepFive.upload.identity': 'Upload identity file',
  'stepFive.upload.address': 'Upload address Proof file',
  'stepFive.form.documents.empty': 'Veuillez fournir tous les documents requis',
  'stepFive.form.field.empty': 'Champ vide',
  'stepFive.create.success': 'Informations sur dirigeant ajoutées avec succès',
  'stepFive.form.proof.empty': 'Veuillez choisir un document correspondant',
  'stepFive.createPerson.accept': `Veuillez confirmer la véracité des informations renseignées`,
  'stepFive.form.empty.name': 'Le champ nom ne doit pas être vide',
  'stepFive.form.empty.firstName': 'Le champ prénom ne doit pas être vide',
  'stepFive.form.nationality.empty': 'Veuillez renseigner votre citoyenneté',
  'stepFive.form.empty.birthday': 'Veuillez renseigner votre date de naissance',
  'stepFive.form.empty.placeOfBirthday':
    'Veuillez renseigner votre lieu de naissance',
  'stepFive.form.empty.birthCountry':
    'Veuillez renseigner votre pays de naissance',
  'stepFive.form.empty.country': 'Veuillez renseigner votre pays',
  'stepFive.form.empty.birthPostCode':
    'Veuillez renseigner le code postal de votre ville de naissance',
  'stepFive.form.empty.address': 'Veuillez renseigner votre adresse ',
  'stepFive.form.empty.postCode': 'Veuillez renseigner votre code postal',
  'stepFive.form.empty.city': 'Veuillez renseigner votre ville',
  'stepFive.form.empty.identity':
    'Veuillez choisir un type de document pour votre identité',
  'stepFive.form.empty.addressProof':
    "Veuillez choisir un type de document pour l'adresse",
  'stepFive.form.empty.title': 'Veuillez renseigner votre genre',
  'stepFive.form.empty.type': "Veuillez choisir votre rôle dans l'entreprise",
  'stepFive.form.empty.identityFile': `Veuillez sélectionner un fichier pour justificatif de pièce d'identité`,
  'stepFive.form.empty.addressFile': `Veuillez sélectionner un fichier pour justificatif de domicile`,
  'manager.birthday.notAdult': `Vous devriez être une personne majeur pour pouvoir créer un compte.`,

  'stepFive.form.empty.vigilance': 'Pièce d’identité complémentaire manquante',
  'stepFive.form.empty.icomeRange': 'Veuillez renseigner votre revenu',
  // modal

  'owner.changeLeader': 'Sélectionner le dirigeant',
  'stepFive.modal.title': 'Sélectionner le dirigeant',
  'stepFive.modal.choice': 'Sélectionner',
  'stepFive.incomeRange': 'Revenu (K€)',

  'stepFive.cin.verso': ``,

  'stepFive.form.isHost': `Je suis hébergé(e)`,
  'stepFive.host.identity': `Pièce d'identité de l'hébergeur`,
  'stepFive.host.addressProof': `Justificatif de domicile de l'hébergeur`,
  'stepFive.host.attestation': `Attestation hébergeur`,
  'host.form.addressProof': `Justificatif de domicile de l'hébergeur`,
  'stepFive.host.enough.file': `Veuillez ajouter les 3 fichiers nécessaires en tant qu'hébergée`,
  'stepFive.form.address.agree': `Je certifie que je suis uniquement un résident(e) fiscal français.`,
  'stepFive.form.error.agree': `Veuillez certifier que vous êtes un résident fiscal en France`,

  'liveness.aborted.refusal':
    "L'utilisateur a refusé d'effectuer la vérification maintenant.",
  'liveness.aborted.no_document':
    "L'utilisateur n'avait pas son document sur lui.",
  'liveness.aborted.verify_later':
    'Vous avez quitté le processus de vérification.',
  'liveness.error.no_SMS': "L'utilisateur n'a pas reçu le SMS.",
  'liveness.error.device_not_allowed':
    "L'utilisateur refuse de donner accès à la caméra.",
  'liveness.error.no_support': "Le navigateur n'est pas supporté.",
  'liveness.error.device_not_found':
    "L'appareil de l'utilisateur n'avait pas de caméra.",
  'liveness.error.bad_connexion': "La connexion n'était pas assez bonne.",
  'liveness.error': 'Erreur lors du liveness',
  'liveness.success': `Vous avez terminé la vérification en ligne, veuillez continuer votre parcours de souscription en ligne`,
  'stepfive.button.checking': 'Vérification',
  'liveness.executing.error': `Une erreur s'est produite lors de l'exécution du liveness`,
  'liveness.success.token': `Vérification terminée avec succès`,
};
