export default {
  'addCapital.title': 'Amount of subscriptions',
  'owner.shareholder.subtitle': `Indicate the other shareholders of your company if they are Beneficial Owners (25% or more of the share capital) in accordance with your articles of association.`,
  'addPercent.title': 'Information on Directors and Beneficial Owners',
  'addCapital.subtitle': 'Total amount of initial capital',
  'addCapital.paragraph1': 'Obtain a certificate of deposit',
  'addCapital.li1':
    'Define the amount of your share capital with your shareholders if applicable,',
  'addCapital.li1.percent': 'Define the amount of your share capital.',
  'addCapital.li2': 'Make sure that each partner deposits at least 1€.',
  'addCapital.li3':
    'Do not include contributions in kind in your articles of association.',
  'addCapital.li4':
    'Fully pay up the capital and your shares upon subscription.',
  'addCapital.paragraph2':
    'Please define the subscription amounts per shareholder in accordance with your draft articles of association.',
  'addPercent.paragraph2': `Indicate the Directors/Managers (shareholders or not) and the Beneficial Owners (25% or more of the capital)`,
  'addCapital.label': 'Company name',
  'addCapital.totalAmount': 'Total share capital',
  'addCapital.checkbox':
    'By ticking this box, I confirm that the above information complies with the articles of association as they will be presented to the Registrar of the Commercial Court for the registration of my company.',
  'addPercent.checkbox':
    'I certify that the information is accurate and up to date, and that there are no other directors or beneficial owners.',
  'addCapital.shareholder.type': 'Type',
  'stepSix.error.loadShareholderError':
    'Error retrieving shareholders information.',
  'stepSix.error.addShareholder': `Error while adding shareholders.`,
  'stepSix.error.capitalNotNumber': `Please fullfil shareholders's assets with number.`,
  'stepSix.error.addShareholderCapital': `The amount per shareholder must be higher than 0 €`,
  'stepSix.success.addShareholder': `Shareholders successfully added.`,
  'stepSix.success.addShareholderCapital': `Shareholders's assets add successfully.`,
  'stepSix.error.addCapitalCheckbox':
    'Please confirm the conformity of the informations.',
  'stepSix.shareholder.payment': 'Net amount payable',
  'stepSix.card.owner': 'Owner',
  'stepSix.card.num': 'Card number',
  'stepSix.card.expire': 'Expiration date',
  'stepSix.card.cvc': 'CVC',
  'button.paiement': 'Pay',
  'stepSix.shareholderCapital.name.empty': 'Please set name of beneficiary',
  'stepSix.shareholderCapital.address_zip.empty':
    'Please set the address of beneficiary',
  'stepSix.shareholderCapital.stripe.empty':
    'Please set the card bank information',

  'card.cardHolder.notValid': `Please verify the card holder`,
  'card.cardNumber.notValid': `Please verify the card number`,
  'card.month.notValid': `Please verify the month of expiration`,
  'card.year.notValid': `Please verify the year of expiration`,
  'card.cvc.notValid': `Please verify the CVC`,
  'order.card.success': 'Card paied with success',
  'order.card.error': 'Payment failed',
  'card.cardDialogue.ownerTitle': 'Owner',
  'card.dialog.payement.amountToPay': 'Amount to be paid',
  'card.dialog.payement.cardNumber': 'Number of card',
  'card.payement.pay': 'Pay',

  'stepSix.invite.success': 'Invitation send with success',
  'stepSix.invite.error': `Error when sending invitation`,
  'shareholder.email.empty': `Please provide email address for all shareholder(s)`,

  'stepsix.account.create.failed':
    'Error when creating the account for the shareholder.',

  'capital.label.creditAccount': 'Payment - Capital Deposit Benefit',
  'account.credited.title': `Deposit your capital`,

  'shareholder.type.none': 'Not an employee',
  'shareholder.type.leader': 'Leader',
  'shareholder.type.employee': 'Employee',

  'shareholder.percent.empty': `Add percentage of all shareholder's`,
  'shareholder.director.empty':
    'Please provide the Director or Manager of the company',
  'shareholder.dialog.title': 'Informations',
  'stepSix.beneficiary.label': 'Type',
  'percent.label': 'Percent',

  'shareholders.emails.exists': 'Please check the list of e-mails entered',

  'stepSix.error.addShareholderPercent':
    'Error when inserting percentages for each beneficial owner',

  'stepSix.all.address.agree': `I certify that the shareholders are all French tax residents.`,
};
