import gql from 'graphql-tag';

export const GET_SNACKBAR_STATE = gql`
  query SnackBar {
    snackBar @client {
      type
      message
      isOpen
    }
  }
`;

export const INVITE_URL_EXPIRATION = gql`
  query InviteUrlExpiration {
    inviteUrlExpiration
  }
`;
