export default {
  'corporate.title': `Informations sur l'entreprise`,
  'corporate.incorporationCode': `SIRET`,
  'corporate.incorporationDate': `Date de création`,
  'corporate.incorporationCountry': `Pays de création`,
  'corporate.registeredName': `Raison sociale`,
  'corporate.legalType': `Forme juridique`,
  'corporate.industry': `Activité`,
  'corporate.accountPurposes': `Utilisation du compte`,
  'corporate.vatNumber': `Numéro de TVA`,
  'corporate.expectedMonthlyExpenses': `Dépenses mensuelles prévues`,
  'corporate.employeesRange': `Nombre de salariés`,

  'address.street': `Adresse`,
  'address.postalCode': `Code postal`,
  'address.city': `Ville`,
  'address.isoCountryCode': `Pays`,
  'address.personal': `Adresse personnelle`,

  'member.address.agree': `Je certifie être uniquement résident(e) fiscal français`,
  'member.certify.information': `Je certifie sur l'honneur l'exactitude des informations`,
  'member.certify.usSpecifiedPerson': `Je certifie ne pas être “US Person”`,

  'member.subtitle.owner': 'Informations légales',
  'member.direction.title': `Informations Représentant Légal`,
  'member.director.appointment.date': 'Date de nomination du directeur',
  'member.first.radio': 'Directeur',
  'member.second.radio': 'Mandataire',
  'member.form.gender': 'Genre',
  'member.form.name': 'Nom',
  'member.form.surname': 'Prénom',
  'member.form.nationality': 'Nationalité',
  'member.form.citizenShip': 'Citoyenneté',
  'member.isDirector': 'Je suis Directeur / Gérant ',
  'member.identity.doc': `Pièce d’identité`,
  'member.identity.doc.type': `Type pièce d'identité`,
  'document.ubo.registry': 'Document du Registre des Bénéficiaires Effectifs',
  'document.company.registry': 'Extrait du Registre des Sociétés',
  'document.articles.association': 'Statuts de la société',
  'document.proof.address': 'Justificatif de Domicile',

  'accountPurposes.pay.bills': `Payer des factures`,
  'accountPurposes.money.to.from.family': `Envoi/réception d'argent à/de la famille`,
  'accountPurposes.dividends': `Dividendes`,
  'accountPurposes.savings': `Épargne`,
  'accountPurposes.personal.business.purposes': `Activités professionnelles personnelles`,
  'accountPurposes.pension': `Pension`,
  'accountPurposes.crypto': `Crypto`,
  'accountPurposes.salary': `Salaire`,
  'accountPurposes.lottery.gaming': `Loterie/Jeux d'argent`,
  'accountPurposes.reconciliation': `Rapprochement`,
  'accountPurposes.virtual.account': `Compte virtuel`,
  'accountPurposes.exchange': `Change de devises`,
  'accountPurposes.other': `Autre`,
};
