import { useApolloClient, useQuery } from "@apollo/react-hooks";
import { green, red } from "@material-ui/core/colors";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { Theme } from "@material-ui/core/styles";
import withStyles from "@material-ui/styles/withStyles";
import React from "react";
import { GET_SNACKBAR_STATE } from "../../../graphql/Common/snackbar/query";
import { MESSAGE_TYPE } from "../../../utils/constant";

const styles = (theme: Theme) => ({
  snackSuccess: {
    backgroundColor: green[600],
    fontSize: 16,
  },
  snackError: {
    backgroundColor: red[600],
    fontSize: 16,
  },
});

const GlobalSnackBar = (props: any) => {
  const { classes } = props;
  const client = useApolloClient();
  const closeSnack = () => {
    client.writeData({
      data: {
        snackBar: {
          isOpen: false,
          __typename: "SnackBar",
        },
      },
    });
  };
  const { data } = useQuery(GET_SNACKBAR_STATE, { fetchPolicy: "cache-only" });
  if (!data) {
    return null;
  }
  const { isOpen, message, type } = data.snackBar
    ? data.snackBar
    : { isOpen: false, message: "", type: MESSAGE_TYPE.ERROR };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={isOpen}
      autoHideDuration={5000}
      onClose={closeSnack}
    >
      <SnackbarContent
        onClick={closeSnack}
        className={
          type === MESSAGE_TYPE.SUCCESS
            ? classes.snackSuccess
            : classes.snackError
        }
        message={message}
      />
    </Snackbar>
  );
};

export default withStyles(styles)(GlobalSnackBar);
