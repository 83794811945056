export default {
  "maxdate.message": `Enter a valid date`,

  "corporate.registeredName.empty": `Please enter the company's registered name`,
  "corporate.legalType.empty": `Please enter the company's legal form`,
  "corporate.industry.empty": `Please enter the company's activity`,
  "corporate.accountPurposes.empty": `Please specify the purpose of the account.`,
  "corporate.expectedMonthlyExpensesValue.empty": `Please enter the company's expected monthly expenses`,
  "corporate.incorporationDate.empty": `Please enter the company's registration date`,
  "corporate.incorporationCountry.empty": `Please enter the country where the company was registered`,
  "corporate.incorporationCode.empty": `Please enter the company's SIRET code`,
  "corporate.employeesRange.empty": `Please enter the number of employees in the company`,

  "address.postalCode.empty": `Please enter the postal code`,
  "address.street.empty": `Please enter the address`,
  "address.isoCountryCode.empty": `Please enter the country`,
  "address.city.empty": `Please enter the city`,

  "onboarding.retrieving.information.error": `Error retrieving information about the company.`,

  "member.type.empty": `Please select your role in the company`,
  "member.director.appointment.date.empty": `Please enter your appointment date as a director`,
  "member.name.empty": `Please enter your last name`,
  "member.firstName.empty": `Please enter your first name`,
  "member.nationality.empty": `Please enter your citizenship`,
  "member.usPerson.empty": `This field is required`,
  "member.gender.empty": `Please enter your gender`,
  "member.form.error.agree": `Please certify that you are a tax resident in French.`,
  "member.error.accept": `Please certify the accuracy of filled informations.`,
  "member.email.already.exists": `This email address already exists, please change.`,
  "member.phone.already.exists": `This phone number already exists, please change it.`,
  "member.phone.empty": `Please enter your telephone number.`,
  "member.email.empty": `Please enter your email address.`,
  "member.doc.empty": `Please fill out your document.`,
  "member.doc.type.empty": `Please specify the type of document`,

  "create.corporate.error": `Error during the creation of your company.`,
  "create.corporate.already.exists": `This company already exists with us. Please verify your SIRET or country of incorporation.`,
  "document.upload.incomplete":
    "Please provide an extract from the Company Registry (Kbis extract)",
  "file.upload.error": "File upload error. Please try again.",

  "check.payment.stripe.status.error": `Error during the verification of the account opening fee payment.`,
  "create.payment.stripe.error": `Unable to load the account opening fee payment page. Please try again later or contact technical support if the issue persists.`,
  "payment.stripe.not.paid": `You have not yet paid the processing fee for opening your account. Please complete the payment to finalize your registration.`,
};
