export const API_URL = process.env.REACT_APP_API_URL;
export const DASHBOARD_URL = process.env.REACT_APP_DASHBOARD_URL;
export const PRIVACY_POLICY_URL =
  process.env.REACT_APP_PRIVACY_POLICY_URL ?? `/Privacy Policy Sapheer.pdf`;
export const CONDITION_SAPHEER_LINK =
  process.env.REACT_APP_CONDITION_SAPHEER_LINK ??
  `/CGS - Sapheer - LinkCy & Paynovate.pdf`;
export const CONDITION_LINKCY_LINK =
  process.env.REACT_APP_CONDITION_LINKCY_LINK ??
  `/GTCU_T&C LinkCy x PNVT_Accounts_Corporates_Cards.pdf`;
