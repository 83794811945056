export default {
  'companyInfo.successCreate': 'Company create',
  'stepFour.legalName': 'Name',
  'stepFour.legalForm': 'Form',
  'company.legalName.empty': 'Please set legal name of the company',
  'company.legalForm.empty': 'Please set legal form of the company',
  'company.legalRegistrationNumber.empty': 'Please set the SIRET',
  'company.legalShareCapital.empty': 'Please set your share capital',
  'company.legalRegistrationDate.empty': 'Please set the registration date',
  'company.postCode.empty': 'Please set the postal code',
  'stepFour.legalRegistrationNumber': 'SIRET',
  'stepFour.legalShareCapital': 'share capital',
  'stepFour.legalRegistrationDate': 'Registration date',
  'stepFour.address': 'Address',
  'stepFour.postCode': 'Postal code',
  'stepFour.city': 'City',
  'stepFour.search.placeholder': 'SIRET',
  'company.create.success': 'Information company create with success',
  'company.maxdate.message': 'Date invalid',
  'stepFour.search.buttom': 'Search',

  'company.search.message.empty': `If your company is not listed, please click on the button below to enter the information manually: `,
  'company.redirect.empty': `Enter manually`,

  'stepFour.legalSector': `Legal sector (Code NAF)`,
  'stepFour.legalNumberOfEmployeeRange': `Legal number of employee range`,
  'stepFour.legalAnnualTurnOver': `Legal annual turn over (K€)`,
  'stepFour.legalNetIncomeRange': `Legal net income range (K€)`,
  'stepFour.creditsafe': `Credit safe`,

  'stepFour.file.urssaf': `URSSAF certificate`,
  'stepFour.file.virement': `Incoming transfer of €20 from an account in the same name`,
  'stepFour.file.orderProfessional': `Document justifying the status of a Professional Order`,
  'stepFour.file.insee': 'INSEE opinion',
  'stepFour.file.sirene': `Situation report SIRENE`,
  'company.legalSector.notValid': `Please check the company's sector of activity (Code NAF)`,
};
