export default {
  'stepTwo.button.next': 'Next',
  'stepTwo.button.previous': 'Previous',
  'stepTwo.condition.text':
    'You will only be debited with your monthly subscription when your account is provisioned ',

  'stepTwo.accept.first.cgsSapheer': `I have read and agree `,
  'stepTwo.accept.second.cgsSapheer': `the general conditions of service - Sapheer`,
  'stepTwo.accept.first.gtLinkcy': `I have read and agree `,
  'stepTwo.accept.second.gtLinkcy': `the general conditions of use - LinkCy`,

  'stepTwo.accept.link': 'general conditions of use',
  'stepTwo.accept.end': ' of Sapheer',
  'stepTwo.noOfferId': 'Please select an offer',
  'stepTwo.noCondition': 'Please accept general conditions of service.',
  'stepTwo.cgum.noCondition':
    'Please accept general conditions of use of payment services.',
  'stepTwo.cguc.noCondition':
    'Please accept general conditions of use of cards.',
  'stepTwo.selectOfferError': 'Error when selecting offer.',

  'offer.free': 'Free',

  'offer.servicePlus': 'Service Plus',
  'offer.liveCard': 'Live Card',
  'offer.smartDashboard': 'Smart Dashboard',
  'offer.categorisationAuto': 'Automatic Categorization',

  'offer.fonctionality': 'Fonctionality',
  'offer.users': 'Users',
  'offer.smartAccess': 'Smart Access',
  'offer.encaissement': 'collections',
  'offer.cheque': 'Cheque',
  'offer.quantity': 'Quantity',
  'offer.withdrawal': 'Withdrawal',
  'offer.payinsAndpayouts': 'Payins / Payout',
  'offer.cardTransaction': 'Card transaction',
  'offer.virtual': 'Virtual',
  'offer.physical': 'Physical',
  'offer.businnesMastercard': 'Business Mastercard',
  'offer.noLimit': 'No limit',
  'offer.limitAtmWeek': 'Withdrawal limit / week',
  'offer.limitPaymentMonth': 'Payment limit / month',
  'offer.autorized': 'Authorized',
  'offer.selected': 'Selected',
};
