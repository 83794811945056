import _ from 'lodash';
import { NATIONALITIES } from './nationalities';

export const DEFAULT_PASSWORD = '0000';
export const LEGALFORM_FREELANCER = '1000';
export const SAPHEER_COLOR = '#329AEC';
export enum MESSAGE_TYPE {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  INFO = 'INFORMATION',
}

export enum HIPAY_ORDER {
  TOPUP = 'TOPUP',
  FACTURE = 'FACTURE',
  DEPOSIT_CAPITAL = 'DEPOSIT_CAPITAL',
}

export enum OfferNames {
  START = 'Start',
  SMART = 'Smart',
  PREMIUM = 'Premium',
  CORPORATE = 'Corporate',
}

export enum TreezorDocType {
  ADDRESS_PROOF_DOC = 'ADDRESS_PROOF_DOC',
  VIGILANCE_SUP_DOC = 'VIGILANCE_SUP_DOC',
  IDENTITY_DOC = 'IDENTITY_DOC',
  HOSTING_PROOF_IDENTITY = 'HOSTING_PROOF_IDENTITY',
  HOSTING_PROOF_ADDRESS = 'HOSTING_PROOF_ADDRESS',
  HOSTING_PROOF_ATTESTATION = 'HOSTING_PROOF_ATTESTATION',
  COMPANY_REGISTRATION = 'COMPANY_REGISTRATION',
  COMPANY_STATUS = 'COMPANY_STATUS',
  // New
  COMPANY_URSSAF = 'COMPANY_URSSAF',
}

export enum CIN_ENUM {
  RECTO = 'RECTO',
  VERSO = 'VERSO',
}

export enum PERSON_TYPE {
  LEADER = 1,
  SHAREHOLDER = 2,
  LEADER_MENDATOR = 3,
  EMPLOYE = 4,
}

export enum ShareholderType {
  NONE = 0,
  LEADER = 1,
  EMPLOYE = 2,
}

export const GENDER_ITEMS = [
  { id: 'M', label: 'Mr' },
  { id: 'MME', label: 'Mme' },
];

export const SORTED_NATIONALITIES = _.sortBy(NATIONALITIES, 'label');

export const PHONE_REGEXP: RegExp = /^(?:00|\\+)/;

export const VALID_PHONE_REGEXP: RegExp = /^(?:00|\\+)/;

export const DEFAULT_OFFER_NAME = 'Premium';

export enum CURRENCY {
  EUR = 'EUR',
}

export enum CURRENCY_SYMBOL {
  EUR = '€',
  USD = '$',
  GBP = 'GBP',
}
export enum CARD_PRICE {
  MONTHLY = '1.99',
  PAY_ONCE = '29.99',
}

export enum ERROR_MESSAGE {
  COMPANY_ALREADY_VALIDATED = 'COMPANY_ALREADY_VALIDATED',
  EMAIL_EXIST = 'EMAIL_EXIST',
  HOSTED_DOCS_LENGTH_ERROR = 'HOSTED_DOCS_LENGTH_ERROR',
}

export enum FILE_TYPE {
  pdf = 'application/pdf',
}
