export default {
  'companyInfo.successCreate': 'Entreprise créer',
  'stepFour.legalName': 'Raison sociale',
  'stepFour.legalForm': 'Forme juridique',
  'company.legalName.empty':
    "Veuillez renseigner la raison sociale de l'entreprise",
  'company.legalForm.empty':
    "Veuillez renseigner la forme juridique de l'entreprise",
  'company.legalRegistrationNumber.empty': 'Veuillez renseinger SIRET',
  'company.legalShareCapital.empty': 'Veuillez renseinger la capital social',
  'company.legalRegistrationDate.empty':
    'Veuillez renseigner la date de création',
  'company.postCode.empty': 'Veuillez renseigner le code postal',
  'stepFour.legalRegistrationNumber': 'SIRET',
  'stepFour.legalShareCapital': 'Capital social',
  'stepFour.legalRegistrationDate': 'Date de création',
  'stepFour.address': 'Adresse',
  'stepFour.postCode': 'Code postal',
  'stepFour.city': 'Ville',
  'stepFour.search.placeholder': 'SIRET',
  'company.create.success': 'Entreprise créée avec succès',
  'company.maxdate.message': 'Entrer une date valide',
  'stepFour.search.buttom': 'Rechercher',

  'company.search.message.empty': `Si votre entreprise n'est pas répertoriée dans la liste, veuillez cliquer sur le bouton ci-dessous pour entrer manuellement les informations : `,
  'company.redirect.empty': `Saisir manuellement`,
  'stepFour.legalSector': `Secteur d’activité (Code NAF)`,
  'stepFour.legalNumberOfEmployeeRange': `Nombre de salariés`,
  'stepFour.legalAnnualTurnOver': `Chiffre d’affaire annuel (K€)`,
  'stepFour.legalNetIncomeRange': `Revenu annuel (K€)`,
  'stepFour.creditsafe': `Numéro de TVA`,
  'stepFour.file.urssaf': `Attestation URSSAF`,
  'stepFour.file.virement': `Virement entrant de 20€ provenant d'un compte au même nom`,
  'stepFour.file.orderProfessional': `Document justifiant le statut d'un Ordre professionnel`,
  'stepFour.file.insee': 'Avis INSEE',
  'stepFour.file.sirene': `Avis de situation SIRENE`,
  'company.legalSector.notValid': `Veuillez vérifier le secteur d'activité de l'entreprise (Code NAF)`,
};
