import React, { FC, Fragment } from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import theme from "./theme";
import Routes from "./Route";
import GlobalSnackBar from "./components/Common/GlobalSnackBar/GlobalSnackBar";
import CssBaseline from "@material-ui/core/CssBaseline";

const App: FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalSnackBar />
      <BrowserRouter>
        <Fragment>
          <Routes />
        </Fragment>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
