import { createMuiTheme } from '@material-ui/core/styles';
import './index.css';

const defaultTheme = createMuiTheme();

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#329AEC',
    },
    secondary: {
      main: '#00E5FF',
    },
    text: {
      primary: '#252551',
    },
  },
  typography: {
    fontFamily: 'Montserrat',
    h2: { fontSize: '1.25rem', lineHeight: 1.5 },
    h6: {
      fontWeight: 700,
    },
    subtitle2: {
      lineHeight: 2,
      fontWeight: 700,
    },
    body1: {
      fontSize: '.875rem',
      lineHeight: 2,
    },
    body2: {
      fontSize: '.75rem',
    },
  },
  overrides: {
    MuiStepIcon: {
      root: {
        color: 'transparent',
        border: `1px solid ${defaultTheme.palette.common.white}`,
        borderRadius: '50%',
      },
      active: {
        color: 'transparent !important',
      },
      completed: {
        color: `${defaultTheme.palette.common.white} !important`,
      },
    },
    MuiStepLabel: {
      label: {
        color: 'transparent',
      },
      active: {
        color: `${defaultTheme.palette.common.white} !important`,
      },
      completed: {
        color: `${defaultTheme.palette.common.white} !important`,
      },
    },
    MuiStepConnector: {
      line: {
        borderColor: defaultTheme.palette.common.white,
      },
    },
    MuiStepContent: {
      root: {
        borderColor: defaultTheme.palette.common.white,
        color: `${defaultTheme.palette.common.white} !important`,
      },
    },
    MuiButton: {
      root: {
        fontWeight: 700,
        marginRight: defaultTheme.spacing(2),
      },
      contained: {
        padding: defaultTheme.spacing(1.25, 2),
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: defaultTheme.spacing(2, 1.75),
      },
    },
  },
});

export default theme;
