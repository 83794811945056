export default {
  'hipay.accept': 'Your transaction has been registered',
  'hipay.pending': 'Your transaction is pending validation',
  'hipay.cancel': 'Your transaction has been cancelled',
  'hipay.decline': 'Your transaction has been declined',
  'hipay.exception': 'Exception error when executing your transaction',
  // Error from hipay
  ERROR_CARD_HOLDER_ALPHANUMERICAL: 'Card holder should be alphanumerical.',
  ERROR_CARD_HOLDER_MAX_LENGTH: 'Card holder maximum length is exceeded.',
  ERROR_CARD_HOLDER_MAX_DIGITS:
    'Card holder should not contain more than 8 digits.',
  ERROR_CARD_HOLDER_INVALID: 'Card holder is invalid.',
  ERROR_CARD_NUMBER_INVALID: 'Card number is invalid.',
  ERROR_CARD_BRAND_NOT_ALLOWED: 'Card type is not allowed.',
  ERROR_EXPIRY_DATE_INVALID_FORMAT: 'Expiry date format is invalid.',
  ERROR_EXPIRY_DATE_PAST_DATE: "Expiry date can't be in the past.",
  ERROR_EXPIRY_DATE_INVALID: 'Expiry date is invalid.',
  ERROR_EXPIRY_MONTH_INVALID_FORMAT: 'Expiry month format is invalid.',
  ERROR_EXPIRY_MONTH_LENGTH_MAX: 'Expiry month maximum length is exceeded.',
  ERROR_EXPIRY_MONTH_INVALID: 'Expiry month is invalid.',
  ERROR_EXPIRY_YEAR_INVALID_FORMAT: 'Expiry year format is invalid.',
  ERROR_EXPIRY_YEAR_LENGTH_MAX: 'Expiry year max length is exceeded.',
  ERROR_EXPIRY_YEAR_PAST_DATE: "Expiry year can't be in the past.",
  ERROR_EXPIRY_YEAR_INVALID: 'Expiry year is invalid.',
  ERROR_CARD_CVC_FORMAT: 'CVC format is invalid.',
  ERROR_CARD_CVC_MAX_LENGTH: 'CVC max length is exceeded.',
  ERROR_CARD_CVC_INVALID: 'CVC is invalid.',
  ERROR_CARD_HOLDER_MISSING: 'Card holder is missing.',
  ERROR_CARD_NUMBER_MISSING: 'Card number is missing.',
  ERROR_EXPIRY_DATE_MISSING: 'Expiry date is missing.',
  ERROR_CARD_CVC_MISSING: 'CVC is missing.',
  ERROR_CARD_TOKENIZATION: 'An error occurred during tokenization.',
  ERROR_BIC_FORMAT: 'BIC format is invalid.',
  ERROR_BIC_MAX_LENGTH: 'BIC maximum length is exceeded.',
  ERROR_BIC_INVALID: 'BIC is invalid.',
  ERROR_IBAN_FORMAT: 'IBAN format is invalid.',
  ERROR_IBAN_NOT_ALPHANUMERICAL: 'IBAN should be alphanumerical.',
  ERROR_IBAN_INVALID: 'IBAN is invalid.',
  ERROR_IBAN_INVALID_COUNTRY_CODE: 'IBAN country code is invalid.',
  ERROR_IBAN_MAX_LENGTH: 'IBAN maximum length is exceeded.',
  ERROR_TEXT_FIELD_INVALID: 'Text field is invalid.',
  ERROR_TEXT_FIELD_INVALID_CHARACTERS:
    'Text field contains invalid characters.',
  ERROR_TEXT_FIELD_MAX_LENGTH: 'Text field maximum length is exceeded.',
  ERROR_TEXT_FIELD_MAX_DIGITS:
    'Text field should not contain more than 8 digits.',
  ERROR_CPF_INVALID: 'CPF is invalid.',
  ERROR_CPF_NUMERICAL: 'CPF should be numerical',
  ERROR_CPF_MAX_LENGTH: 'CPF maximum length is exceeded.',
  ERROR_CURP_INVALID: 'CURP is invalid.',
  ERROR_CURP_ALPHANUMERICAL: 'CURP should be alphanumerical',
  ERROR_CURP_MAX_LENGTH: 'CURP maximum length is exceeded.',
  ERROR_BANK_NAME_MISSING: 'Bank name is missing.',
  ERROR_FIRSTNAME_MISSING: 'Firstname is missing.',
  ERROR_LASTNAME_MISSING: 'Lastname is missing.',
  ERROR_COMPANY_MISSING: 'Company name is missing.',
  ERROR_IBAN_MISSING: 'IBAN is missing.',
  ERROR_ISSUER_BANK_ID_MISSING: 'BIC is missing.',
  ERROR_NATIONAL_IDENTIFICATION_NUMBER_MISSING:
    'National identification number is missing.',
  ERROR_OCCURED: 'An error occured.',


  'payment.phone.empty': 'Please insert a number phone'
};
