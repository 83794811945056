export default {
  'depositAfter.title': 'Deposit your capital',
  'depositAfter.paragraph': 'I swear on my honour to honour my commitments to Sapheer',
  'depositAfter.link': 'Link RIB Sapheer',
  'deposit.agree': 'Please honor your commitments to Sapheer ',
  'stepFour.register': `Register of companies`,
  'stepFour.create.register': 'K-Bis less than 3 months old',
  'stepFour.docProof': `Certified articles of association`,
  'stepFour.exist.docProof': `Certified articles of association < 1 year`,
  'company.documents.enough': `Please add two company documents`,
  'company.create.documents.enough': `Please add at least one company documents`
};
