export default {
  'stepTwo.button.next': 'Suivant',
  'stepTwo.button.previous': 'Précédent',
  'stepTwo.condition.text':
    'Vous ne serez débité de votre abonnement mensuel que lorsque votre compte ait été approvisionné',
  'stepTwo.accept.first.cgsSapheer': `J'ai lu et j'accepte les `,
  'stepTwo.accept.second.cgsSapheer': `Conditions générales de services - Sapheer`,
  'stepTwo.accept.first.gtLinkcy': `J'ai lu et j'accepte les `,
  'stepTwo.accept.second.gtLinkcy': `Conditions générales d'utilisation - LinkCy`,

  'stepTwo.accept.link': "conditions générales d'utilisations",
  'stepTwo.accept.end': ' de Sapheer',
  'stepTwo.noOfferId': 'Veuillez sélectionner une offre',
  'stepTwo.noCondition': `Veuillez accepter les conditions générales de service.`,
  'stepTwo.cgum.noCondition': `Veuillez accepter les conditions générales d'utilisations des services de paiement.`,
  'stepTwo.cguc.noCondition': `Veuillez accepter les conditions générales d'utilisations des cartes.`,
  'stepTwo.selectOfferError': "Erreur lors de la sélection de l'offre.",

  'offer.free': 'Gratuit',

  'offer.servicePlus': 'Service Plus',
  'offer.liveCard': 'Live Card',
  'offer.smartDashboard': 'Smart Dashboard',
  'offer.categorisationAuto': 'Catégorisation Automatique',

  'offer.fonctionality': 'Fonctionnalités',
  'offer.users': 'Utilisateurs',
  'offer.smartAccess': 'Smart Access',
  'offer.encaissement': 'Encaissements',
  'offer.cheque': 'Chèques',
  'offer.quantity': 'Quantité',
  'offer.withdrawal': 'Retraits',
  'offer.payinsAndpayouts': 'Virements / Prélèvements',
  'offer.cardTransaction': 'Paiements par carte',
  'offer.virtual': 'Virtuelle',
  'offer.physical': 'Physique',
  'offer.businnesMastercard': 'Business Mastercard',
  'offer.noLimit': 'Illimités',
  'offer.limitAtmWeek': 'Limite retrait / semaine',
  'offer.limitPaymentMonth': 'Limite paiement / mois',
  'offer.autorized': 'Autorisé',
  'offer.selected': 'sélectionner',
};
