export default {
  'contract.title': 'Veuillez signer numériquement votre contrat en bas.',
  'contract.paragraphRib': 'Télécharger le RIB de cantonnement de Sapheer',
  'contract.paragraph':
    'Cliquez ci-dessous pour générer votre contrat et le signer numériquement .',
  'contract.subTitle1': `Ce virement permet de valider l’ouverture de votre compte`,
  'contract.subTitle2': `Téléchargez dès à présent le rib pour effectuer votre dépôt de capital.`,
  'contract.button': 'Contrat',
  'contract.buttonDownload': 'RIB pour le dépôt de capital',
  'contract.download.rib.error': 'Problème lors du téléchargement du RIB',
  'contract.downloadContract': 'Télécharger le contrat',
  'contract.label.certification': `Je certifie qu'aucune personne déclarée durant l'inscription n'a d'obligation ou résidence fiscale hors de France.`,
  'contract.label.engagement': `Je m'engage à tenir Sapheer informé dans les plus brefs délais si les informations que j'ai transmises venaient à changer.`,
  'error.getContract':
    'Erreur lors de la récupération du texte du contrat. Veuillez réessayer ultérieurement.',
  'error.saveSignature': `Erreur lors de la sauvegarde de votre signature. Veuillez réessayer s'il vous plaît.`,
  'success.saveSignature': 'Signature sauvegardée avec succès.',
  'success.alreadySavedSignature': 'Signature déjà sauvegardée.',
  'contract.or': 'OU',
  'contract.loadImage': 'Charger une image',
  'contract.signature': 'Signature virtuelle',
  'contract.error.getInformation':
    'Erreur lors de la récupération des informations du compte. Veuillez réessayer ultérieurement.',
  'contract.error.certifyPersonInfo': `Veuillez certifier les informations concernant les personnes déclarées durant l'inscription.`,
  'contract.error.engagement': 'Veuillez confirmer votre engagement.',
  'contract.error.notSigned': 'Veuillez déposer votre signature.',
  'contract.label.registrationCompleted': 'Inscription terminée',
  'contract.label.text.congratulation': 'Félicitation !',
  'contract.label.accessTheDashboard': 'Accéder au dashboard',
  'contract.text.emailContractSent':
    'Votre compte vient d’être créé, en attendant sa validation, vous recevez votre contrat par e-mail.',
  'error.saveSignature.resend': `Problème de connexion lors de l'enregistrement, merci d'enregistrer à nouveau la signature.`,

  'contract.paragraph.6': 'Récupérez votre',
  'contract.paragraph.7': 'dans l’univers',
  'contract.paragraph.3': 'votre ',
  'contract.paragraph.2': 'numériquement',
  'contract.paragraph.1': 'ci-dessous pour générer votre',

  'contract.paragraph.5': ' un ',
  'contract.paragraph.5.1': ' de ',
  'contract.paragraph.5.2': ' depuis votre ',
  'contract.paragraph.5.3': ' ou depuis un ',
  'contract.paragraph.5.4': ' existant vers votre ',

  'contract.paragraph.word1': 'Cliquez ',
  'contract.paragraph.word2': ' contrat',
  'contract.paragraph.word3': 'Signez-le ',
  'contract.paragraph.word4': 'Accédez à ',
  'contract.paragraph.word5': 'espace Sapheer',
  'contract.paragraph.word6': ' RIB ',
  'contract.paragraph.word7': ' Transactions',

  'contract.paragraph.word8': 'Effectuez ',
  'contract.paragraph.word9': ' virement  ',
  'contract.paragraph.word10': ' 40€ ',
  'contract.paragraph.word11': ' compte personnel ',
  'contract.paragraph.word12-1': ' compte ',
  'contract.paragraph.word12-2': ' société ',
  'contract.paragraph.word13': ' compte Sapheer',
};
