import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import { createClient } from './apolloClient';
import './index.css';
import App from './App';
import en from 'react-intl/locale-data/en';
import fr from 'react-intl/locale-data/fr';
import messages from './locales/messages';
import { addLocaleData, IntlProvider } from 'react-intl';
import * as serviceWorker from './serviceWorker';
import { CrossStorageHub } from 'cross-storage';
import { DASHBOARD_URL } from './config';
import { ACCESS_TOKEN_KEY } from './services/LocalStorage';

CrossStorageHub.init([
  { origin: new RegExp(`${DASHBOARD_URL}`), allow: ['get'] },
]);
const supportedLanguages = ['en-US', 'fr-FR'];

let locale: string =
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  'en-US';

if (!supportedLanguages.find((l) => l === locale)) {
  locale = 'fr-FR';
}

if (localStorage.getItem('language')) {
  locale = localStorage.getItem('language') || '';
}

addLocaleData([...en, ...fr]);
const token = localStorage.getItem(ACCESS_TOKEN_KEY);
const client = createClient(token);

ReactDOM.render(
  <ApolloProvider client={client}>
    <IntlProvider locale={locale} messages={(messages as any)[locale]}>
      <App />
    </IntlProvider>
  </ApolloProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
