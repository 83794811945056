import gql from 'graphql-tag';
import {
  DOCUMENT_FRAGMENT,
  DOCUMENT_TYPE_FRAGMENT,
} from '../document/fragment';

const PERSON_FRAGEMENT = gql`
  fragment Person on Person {
    id
    type
    title
    name
    firstName
    nationality
    birthday
    placeOfBirth
    birthCountry
    birthPostCode
    address
    postCode
    city
    capital
    percent
    employeeType
    specifiedUSPerson
    isPersonHosted
    documents {
      ...DocumentInfo
      type {
        ...DocumentTypeInfo
      }
    }
    account {
      id
      email
      phone
    }
  }
  ${DOCUMENT_FRAGMENT}
  ${DOCUMENT_TYPE_FRAGMENT}
`;

export { PERSON_FRAGEMENT };
