export default {
  'depositAfter.title': 'Déposer votre capital',
  'depositAfter.paragraph': "Je jure sur l'honneur d'honorer mes engagements envers Sapheer",
  'depositAfter.link': 'Lien RIB Sapheer',
  'deposit.agree': 'Veuillez honorer votre engagements envers Sapheer',
  'stepFour.register': `Registre de la société`,
  'stepFour.create.register': 'K-Bis de moins de 3 mois ',
  'stepFour.docProof': `Statuts de la société - Certifiés conformes`,
  'stepFour.exist.docProof': `Statuts certifiés conformes de moins d'un an`,
  'company.documents.enough': `Veuillez ajouter deux pièces justificatives de l'entreprise`,
  'company.create.documents.enough': `Veuillez ajouter au moins une pièces justificatives de l'entreprise`
};
