export const ACCESS_TOKEN_KEY = btoa('access_token');
const COMPANY_NAME_KEY = btoa('company_name');
export const COMPANY_ID = btoa('companyId');
export const TVA_NUMBER = btoa('tva_number');

export const setAccessToken = (token: string) =>
  localStorage.setItem(ACCESS_TOKEN_KEY, token);
export const isAuthenticated = (): boolean =>
  !!localStorage.getItem(ACCESS_TOKEN_KEY);
export const clearLocalStorage = () => localStorage.clear();
export const getAccessToken = (): string =>
  localStorage.getItem(ACCESS_TOKEN_KEY) || '';

export const storageSetCompanyName = (name: string) =>
  localStorage.setItem(COMPANY_NAME_KEY, name);

export const storageGetCompanyName = () =>
  localStorage.getItem(COMPANY_NAME_KEY);

export const storageCleanCompanyName = () =>
  localStorage.removeItem(COMPANY_NAME_KEY);


export const getCompanyId = () => {
  return localStorage.getItem(COMPANY_ID);
}
export const setCompanyId = (value: string) => {
  return localStorage.setItem(COMPANY_ID, value)
}
export const removeCompanyId = () => {
  localStorage.removeItem(COMPANY_ID);
}

export const setTvaNumber = (value: string) => {
  return localStorage.setItem(TVA_NUMBER, value)
}
export const getTvaNumber = () => {
  return localStorage.getItem(TVA_NUMBER);
}
export const removeTvaNumber = () => {
  localStorage.removeItem(TVA_NUMBER);
}
