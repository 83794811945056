export default {
  'addCapital.title': 'Montant des souscriptions',
  'owner.shareholder.subtitle': `Indiquez les autres actionnaires de votre entreprise s'ils sont Bénéficiaires Effectifs (25% ou plus du capital social) conformément à vos status.`,
  'addPercent.title':
    'Informations sur les Directeurs et Bénéficiaires Effectifs',
  'addCapital.subtitle': 'Montant total du capital initial',
  'addCapital.paragraph1': 'Obtenir un certificat de dépôt',
  'addCapital.li1':
    'Définissez le montant de votre capital social avec vos associés le cas échéant.',
  'addCapital.li1.percent': 'Définissez le montant de votre capital social.',
  'addCapital.li2': 'Vérifiez que chaque associé dépose au minimum, 1€.',
  'addCapital.li3': "N'introduisez pas d'apports en nature dans vos statuts.",
  'addCapital.li4':
    'Libérez intégralement le capital et vos actions dès souscription.',
  'addCapital.paragraph2':
    'Veuillez définir les montants des souscriptions par actionnaire conformément à vos projets de statuts.',
  'addPercent.paragraph2': `Indiquez les Directeurs / Gérants ( actionnaires ou non ) et les Bénéficiaires Effectifs ( 25% ou plus du capital )`,
  'addCapital.label': 'Capital social',
  'addCapital.totalAmount': 'Capital social total',
  'addCapital.checkbox':
    "En cochant cette case, je confirme que les informations ci-dessus sont conformes aux statuts tels que les présenterai au Greffe du tribunal de commerce pour l'immatriculation de mon entreprise.",
  'addPercent.checkbox': `Je certifie que les informations sont conformes et à jour, et qu'il n'existe aucun autre directeur ou bénéficiaire effectif.`,
  'addCapital.shareholder.type': 'Type',
  'stepSix.error.loadShareholderError': `Erreur lors de la récupération des informations sur les actionnaires.`,
  'stepSix.error.addShareholder': `Erreur lors de l'ajout des actionnaires.`,
  'stepSix.error.capitalNotNumber': `Veuillez compléter les champs capitals avec des nombres.`,
  'stepSix.error.addShareholderCapital': `Le montant par actionnaire doit être supérieur à 0 €`,
  'stepSix.success.addShareholder': `Actionnaires ajoutés avec succès.`,
  'stepSix.success.addShareholderCapital':
    'Capitaux des actionnaires ajoutés avec succès.',
  'stepSix.error.addCapitalCheckbox':
    'Veuillez confirmer la conformité des informations.',
  'stepSix.shareholder.payment': 'Montant net à payer',
  'stepSix.card.owner': 'Propriétaire',
  'stepSix.card.num': 'Numéro de carte',
  'stepSix.card.expire': "Date d'expiration",
  'stepSix.card.cvc': 'CVC',
  'button.paiement': 'Payer',
  'stepSix.shareholderCapital.name.empty':
    'Veuillez entrer le nom du bénéficiaire',
  'stepSix.shareholderCapital.address_zip.empty':
    'Veulilez entrer l"address du bénéficiaire',
  'stepSix.shareholderCapital.stripe.empty':
    'Veulilez entrer les informations concernant votre carte bancaire',
  'card.cardHolder.notValid': `Veuillez vérifier le nom du propriétaire de la carte`,
  'card.cardNumber.notValid': `Veuillez vérifier le numéro de la carte`,
  'card.month.notValid': `Veuillez vérifier le mois d'expiration`,
  'card.year.notValid': `Veuillez vérifier l'année d'expiration`,
  'card.cvc.notValid': `Veuillez vérifier le CVC`,
  'order.card.success': 'Paiement éffectuer avec succès ',
  'order.card.error': 'Echecs du paiement',
  'card.cardDialogue.ownerTitle': 'Propriétaire',
  'card.dialog.payement.amountToPay': 'Montant à payer',
  'card.dialog.payement.cardNumber': 'Numéro de carte',
  'card.payement.pay': 'Payer',

  'stepSix.invite.success': 'Invitation envoyée avec succès',
  'stepSix.invite.error': `Erreur lors de l'envoi de l'email d'invitation `,
  'shareholder.email.empty': `Veuillez renseigner l'adresse mail de chaque(s) actionnaire(s)`,

  'stepsix.account.create.failed': `Erreur lors de la création du compte pour l'actionnaire.`,

  'capital.label.creditAccount': 'Paiement - Prestation dépôt de capital',
  'account.credited.title': 'Dépôt de capital',

  'shareholder.type.none': `Non employé`,
  'shareholder.type.leader': 'Dirigeant',
  'shareholder.type.employee': 'Employé',
  'shareholder.percent.empty': 'Ajouter un pourcentage pour chaque actionnaire',
  'shareholder.director.empty': `Veuillez renseigner le directeur ou le gérant de l'entreprise`,
  'shareholder.dialog.title': 'Informations',
  'stepSix.beneficiary.label': 'Type',
  'percent.label': 'Pourcentage',
  'shareholders.emails.exists':
    'Veuillez vérifier la liste des e-mails saisies',

  'stepSix.error.addShareholderPercent':
    "Erreur lors de l'insertion des pourcentages pour chaque bénéficiaire effectif",

  'stepSix.all.address.agree': `Je certifie que les actionnaires sont tous des résidents fiscaux français`,
};
