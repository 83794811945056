export default {
  'stepFive.subtitle': 'My right information',
  'stepFive.first.radio': 'Director',
  'stepFive.second.radio': 'Agent',
  'stepFive.form.gender': 'Gender',
  'stepFive.form.name': 'Name',
  'stepFive.form.surname': 'First name',
  'stepFive.form.nationality': 'Nationality',
  'stepFive.form.citizenShip': 'Citizenship',
  'stepFive.form.dateOfBirth': 'Date of birth',
  'stepFive.form.countryOfBirth': 'Country of birth',
  'stepFive.form.country': 'Country',
  'stepFive.form.zipCode': 'Postal code',
  'stepFive.form.cityOfBirth': 'City of birth',
  'stepFive.form.city': 'City',
  'stepFive.form.identityType': 'Type of identity document',
  'stepFive.form.identityType2': 'Complementary Vigilance',
  'stepFive.form.identityProof': 'Proof of identity document',
  'stepFive.form.personalAddressTitle': 'Home address ',
  'stepFive.form.personalAddress': 'Home address ',
  'stepFive.form.companyAddress': 'Address',
  'stepFive.form.addressType': 'Type of direct debit receipt',
  'stepFive.form.addressProof': 'Proof of address',
  'stepFive.form.checkbox':
    'I certify on my honour the accuracy of the information provided.',
  'stepFive.upload.identity': 'Upload identity file',
  'stepFive.upload.address': 'Upload address Proof file',
  'stepFive.form.documents.empty': 'Document empty',
  'stepFive.form.field.empty': 'Field empty',
  'stepFive.create.success': 'Manager create with success',
  'stepFive.form.proof.empty': 'Choose document type proof',
  'stepFive.createPerson.accept':
    'Please certify the accuracy of filled informations.',
  'stepFive.form.empty.name': 'Please set your name',
  'stepFive.form.empty.firstName': 'Please set your firstname ',
  'stepFive.form.nationality.empty': 'Please set your nationality',
  'stepFive.form.empty.birthday': 'Please set your birthday',
  'stepFive.form.empty.placeOfBirthday': 'Please set your place of birth',
  'stepFive.form.empty.birthCountry': 'Please set your birth country',
  'stepFive.form.empty.country': 'Please set your country',
  'stepFive.form.empty.birthPostCode': 'Please set your birth post code',
  'stepFive.form.empty.address': 'Please set your address',
  'stepFive.form.empty.postCode': 'Please set your post code',
  'stepFive.form.empty.city': 'Please set your city',
  'stepFive.form.empty.identity': 'Please set your identity document type',
  'stepFive.form.empty.addressProof': 'Please set your address document type',
  'stepFive.form.empty.type': 'Please choose your role in the company',
  'stepFive.form.empty.identityFile': `Please select an identity file proof.`,
  'stepFive.form.empty.addressFile': `Please select an address file proof.`,
  'manager.birthday.notAdult': `You should be an adult person to be able to create an account.`,
  'stepFive.form.empty.vigilance': 'Complementary Vigilance empty',
  'stepFive.form.empty.icomeRange': 'Please set your icome range',
  // modal
  'stepFive.modal.title': 'Change leader person',
  'owner.changeLeader': 'Change leader',
  'stepFive.modal.choice': 'Select',
  'stepFive.incomeRange': 'Income Range (K€)',

  'stepFive.cin.verso': ``,
  'stepFive.form.isHost': `I am hosted`,
  'stepFive.host.identity': `Identity card of the hosting provider`,
  'stepFive.host.addressProof': `Proof of address of the hosting`,
  'stepFive.host.attestation': `Hosting certificate`,
  'host.form.addressProof': `Host address proof `,
  'stepFive.host.enough.file': `Please add the 3 necessary files as hosted`,
  'stepFive.form.address.agree': `I certify that I am only a French tax resident.`,
  'stepFive.form.error.agree': `Please certify that you are a tax resident in France`,

  'liveness.aborted.refusal':
    'The user refused to perform the verification now.',
  'liveness.aborted.no_document':
    'The user did not have his document with him.',
  'liveness.aborted.verify_later': 'You have exited the verification process.',
  'liveness.error.no_SMS': 'The user did not receive the SMS.',
  'liveness.error.device_not_allowed':
    'The user refuses to give access to the camera.',
  'liveness.error.no_support': 'The browser was not supported.',
  'liveness.error.device_not_found':
    'The user’s device did not have any camera.',
  'liveness.error.bad_connexion': 'The connection was not good enough.',
  'liveness.error': 'Liveness error',
  'liveness.success':
    'You have completed the online verification, please continue your online application process',
  'stepfive.button.checking': 'Verification',
  'liveness.executing.error': `An error occurred while running liveness`,
  'liveness.success.token': 'Verification completed successfully',
};
