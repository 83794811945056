import gql from 'graphql-tag';
import {
  OFFERS_FRAGMENT,
  ACCOUNT_FRAGMENT,
  SIGNUP_PROGRESSION_FRAGMENT,
  OFFER_FRAGMENT,
} from './fragment';
import { COMPANY_FRAGEMENT } from '../company/fragment';
import { PERSON_FRAGEMENT } from '../person/fragment';

const CHECK_CONNECTION = gql`
  query CheckConnection {
    checkConnection
  }
`;

const OFFERS = gql`
  query Offers {
    offers {
      ...OffersInfo
    }
  }
  ${OFFERS_FRAGMENT}
`;

const INVITE_URL_EXPIRATION = gql`
  query InviteUrlExpiration {
    inviteUrlExpiration
  }
`;

const ME = gql`
  query Me {
    me {
      ...AccountInfo
      offer {
        ...OfferInfo
      }
      existingCompany
      ...AccountProgression
      company {
        ...CompanyInfo
        leader {
          ...Person
        }
        shareholders {
          ...Person
        }
      }
    }
  }
  ${ACCOUNT_FRAGMENT}
  ${OFFER_FRAGMENT}
  ${COMPANY_FRAGEMENT}
  ${PERSON_FRAGEMENT}
  ${SIGNUP_PROGRESSION_FRAGMENT}
`;
export { OFFERS, ME, INVITE_URL_EXPIRATION, CHECK_CONNECTION };
