export default {
  already_account: ' Vous avez déjà un compte ?',
  login: "Inscription",
  signin: 'Se connecter',
  'form.email': 'Email',
  'form.tel': 'Numéro de téléphone',
  'form.password': 'Mot de passe',
  'form.password.contentError':
    'Votre mot de passe doit contenir au moins un caractère minuscule, un caractère majuscule, un chiffre et un caractère spécial',
  'form.confirmPassword': 'Confirmer le mot de passe',
  'dialogue.title': 'CODE DE VALIDATION',
  'dialogue.content':
    "Un code de validation vous a été envoyé par mail, veuillez récupérer le code pour pouvoir poursuivre l'inscription. Si vous n'avez pas encore reçu de code, veuillez ",
  'dialogue.form.valid': 'Valider',
  'dialogue.button.send': ' Cliquer ici',
  'dialogue.message.send':
    'Veuillez accèder à votre email pour avoir le nouveau code',
  'dialogue.message.codeError': 'Merci de reinsérer le code',
  'dialogue.form.cancel': 'Quitter',
  'account.create': 'Compte créer avec succès',
  'account.email.empty': 'Veuillez renseigner votre adresse email ',
  'account.password.empty': 'Mot de passe vide',
  'account.password.notMatch': 'Les mots de passe ne sont pas identiques ',
  'account.data.notValid': 'Email ou mot de passe invalide',
  'account.code.send':
    'Veuillez vérifier votre adresse email et copier ici votre code',
  'account.code.notValid':
    'Le code de confirmation saisi est erronée ou expirée ',
  'account.confirm': 'Compte confirmé avec succès',
  'account.data.password.notValid': `Le mot de passe ne correspond pas au prérequis`,
  'account.data.email.notValid': 'Email invalide',
  'account.phoneNumber.invalid': `Numéro de téléphone invalide`,
  'stepone.authentication.failed': 'Adresse email déjà utilisée',
  'stepone.authentication.password.failed':
    'Veuillez renseigner votre adresse email avec le bon mot de passe',
  'account.empty': 'Veuillez renseigner votre adresse email et mot de passe',
  'error.invalidPassword': 'Mot de passe invalide',
  'pwd.weak': 'Facile',
  'pwd.fair': 'Intermédiaire',
  'pwd.good': 'Bon',
  'pwd.strong': 'Sécurisé',
  'pwd.meter': 'Niveau du mot de passe :',
};
