import gql from 'graphql-tag';

const UPLOAD_FILE_URL_FRAGMENT = gql`
  fragment UrlInfo on UploadFileSignedUrl {
    uuid
    putSignedUrl
  }
`;

const DOCUMENT_TYPE_FRAGMENT = gql`
  fragment DocumentTypeInfo on DocumentType {
    id
    name
    category
  }
`;

const DOCUMENT_FRAGMENT = gql`
  fragment DocumentInfo on Document {
    id
    uuid
    filename
    filetype
    documentTypeId
  }
`;

const DOWNLOAD_FILE_SIGNED_URL_FRAGMENT = gql`
  fragment DownloadFileSignedUrlInfo on DownloadFileSignedUrl {
    getSignedUrl
  }
`;

export {
  UPLOAD_FILE_URL_FRAGMENT,
  DOCUMENT_TYPE_FRAGMENT,
  DOCUMENT_FRAGMENT,
  DOWNLOAD_FILE_SIGNED_URL_FRAGMENT,
};
