export default {
  'contract.title': 'Please sign your contract numerically at the bottom.',
  'contract.paragraphRib': 'Download Sapheer Cantonese RIB',
  'contract.paragraph':
    'By clicking on the button below a contract between you and Sapheer will be generated.',
  'contract.subTitle1': `This transfer validates the opening of your account.`,
  'contract.subTitle2': `Download now the rib to make your capital deposit.`,
  'contract.button': 'Contract',
  'contract.buttonDownload': 'Download the RIB for your capital deposit',
  'contract.download.rib.error': 'Error when downloading RIB',
  'contract.downloadContract': 'Contract with Sapheer',
  'contract.label.certification': `I certify that no person declared during registration has any obligation or tax residence outside France.`,
  'contract.label.engagement': `I undertake to keep Sapheer informed as soon as possible if the information I have provided changes.`,
  'error.getContract':
    'Error while retrieving the contract text. Please retry later.',
  'error.saveSignature': `Error while saving signature. Please try again.`,
  'success.saveSignature': 'Signature saved successfully.',
  'success.alreadySavedSignature': 'Signature already saved.',
  'contract.or': 'OR',
  'contract.loadImage': 'Upload an image',
  'contract.signature': 'Virtual signature',
  'contract.error.getInformation':
    'Error while getting account information. Please retry later.',
  'contract.error.certifyPersonInfo': `Please certify informations about the persons declared during registration.`,
  'contract.error.engagement': 'Please confirm your engagement.',
  'contract.error.notSigned': 'Please deposit your signature.',
  'contract.label.registrationCompleted': 'Registration completed',
  'contract.label.text.congratulation': 'Congratulation !',
  'contract.label.accessTheDashboard': 'Access the dashboard',
  'contract.text.emailContractSent':
    'Your account has just been created, while awaiting validation, you will receive your contract by email.',
  'error.saveSignature.resend': `Connection problem during registration, please save the signature again.`,

  'contract.paragraph.6': 'Get your',
  'contract.paragraph.7': 'in the universe',
  'contract.paragraph.3': 'your ',
  'contract.paragraph.2': 'digitally',
  'contract.paragraph.1': 'below to generate your',

  'contract.paragraph.5': ' a ',
  'contract.paragraph.5.1': ' from ',
  'contract.paragraph.5.2': ' from your ',
  'contract.paragraph.5.3': ' or from your ',
  'contract.paragraph.5.4': ' to your existing ',

  'contract.paragraph.word1': 'Click ',
  'contract.paragraph.word2': ' contract',
  'contract.paragraph.word3': 'Sign it ',
  'contract.paragraph.word4': 'Access to ',
  'contract.paragraph.word5': 'Sapheer space',
  'contract.paragraph.word6': ' RIB ',
  'contract.paragraph.word7': ' Transactions',

  'contract.paragraph.word8': 'Perform ',
  'contract.paragraph.word9': ' transfer  ',
  'contract.paragraph.word10': ' 40€ ',
  'contract.paragraph.word11': ' personal account ',
  'contract.paragraph.word12-1': ' company ',
  'contract.paragraph.word12-2': ' account ',
  'contract.paragraph.word13': ' Sapheer account',
};
