export default {
  maintenance: 'Sapheer est en maintenance, revenez dans quelques minutes',
  'maintenance.title': `Maintenance en cours`,
  'maintenance.subtitle1': `Nous améliorons notre plateforme`,
  'maintenance.subtitle2': `Revenez dans quelques minutes`,
  'signin.title': 'Connexion',
  'header.title': 'Ouvrez votre compte en quelques minutes',
  'company.title': "Informations sur l'entreprise",
  'direction.title': 'Informations sur la direction',
  'direction.title.liveness': `Informations et vérification identité`,
  'owner.title': 'Informations sur les actionnaires',
  'owner.subtitle': "Texte descriptif sur l'ajout ou non des actionnaires.",
  'owner.addButton': 'Ajouter',
  owner: 'Actionnaire',
  'button.previous': 'retour',
  'button.next': 'suivant',
  'server.error': 'Erreur Serveur',
  'network.error': 'Erreur Connexion',
  'upload.file.error': 'Erreur de connexion lors du téléversement du document',
  'other.error': 'Erreur de connexion, veuillez vérifier votre connexion',
  upload: 'Uploader',
  edit: 'Editer',
  'error.isNotAdult':
    'Vous devriez être une personne majeur pour pouvoir créer un compte.',
  'error.upload': `Erreur lors de l'envoi de fichier.`,
  'error.getIdentityDocument': `Erreur lors de la récupération des types de pièces d'identité.`,
  'error.getResidentDocument': `Erreur lors de la récupération des types de justificatif de domiciliation.`,
  'error.downloadingIdentityFile': `Erreur lors du téléchargement de la pièce d'identité.`,
  'error.downloadingAddressFile': `Erreur lors du téléchargement du justificatif de domiciliation.`,
  'error.downloadFile': `Erreur lors du téléchargement du fichier.`,
  'error.uploadOnlyOneFile': 'Veuillez choisir un seul fichier.',
  'error.uploadFileInvalid': 'Veuillez choisir un fichier de type image ou pdf',
  'error.uploadTooLarge': 'Fichier trop large.',
  'error.uploadTooSmall': 'Fichier trop petit.',
  delete: 'Supprimer',
  save: 'Enregistrer',
  'fileUpload.savedDocument': 'Document enregistré:',
  'date.invalid': 'Format date invalide',
  'date.maxdate.message':
    'Vous devriez être une personne majeur pour pouvoir créer un compte.',
  cancel: 'Annuler',
  'error.fileNotSelected': 'Veuillez sélectionner un fichier.',
  'error.nothingSelected': 'Cette sélection ne peut être vide.',
  'error.notEmptyField': 'Ce champ ne peut être vide.',
  or: 'ou',
  'person.checkbox.usSpecifiedPerson': `Je certifie que je ne suis pas une US Person`,

  'stepFive.form.empty.usPerson': 'Cette case est obligatoire',

  'phoneNumber.invalid': 'Le numéro de téléphone doit commencer par 00 ou + ',
  'phone.empty': 'Veuillez renseigner votre numéro de tétéphone',
  // ERROR_CODE
  LEGALNAME_MAX_LENGTH_ERROR:
    'Le nom de votre entrerprise est supérieur à 20 caractères',
  COMPANY_ALREADY_VALIDATED:
    'Cette adresse e-mail est déjà utilisée par un autre utilisateur.',
  EMAIL_EXIST:
    'Cette adresse e-mail est déjà utilisée par un autre utilisateur.',
  'hosted.enough.document': `Veuillez compléter le document de l'hébergeur`,

  'cin.recto': `Carte d'Identité Nationale`,
  'cin.verso': `Carte d'Identité Nationale Verso (Optionnelle)`,

  'identity.file.recto': `Pièce d'identité`,
  'identity.file.verso': `Pièce d'identité Verso (Optionnelle)`,
  'identity2.file.recto': `Vigilance complémentaire`,
  'identity2.file.verso': `Vigilance complémentaire Verso (Optionnelle)`,
  'identity3.file.recto': `Pièce d'identité de l'hébergeur`,
  'identity3.file.verso': `Pièce d'identité de l'hébergeur Verso (Optionnelle)`,

  'common.error.emptySearch':
    'Oops !\nAucun résultat ne correspond à votre recherche',
  'paiement.waiting': `Veuillez patienter...`,
  legal_disclaimer: 'Mentions légales',
  privacy_policy: 'Politique de confidentialité',
  'error.get.leader': `Erreur lors de la récupération des informations sur le dirigeant.`,
  'legal.mention': `Sapheer, une marque exploitée par l'entreprise Spark, propose des comptes et des cartes émis par Paynovate sous licence MasterCard et gérés par LinkCy SAS. Paynovate est autorisée par la Banque Nationale de Belgique en vertu de la loi sur la monnaie électronique et les institutions de monnaie électronique (réf. 0506763929) à émettre de la monnaie électronique et à fournir des services de paiement connexes. LinkCy SAS agit en tant qu'agent de Paynovate, enregistré auprès de la NBB sous le numéro 852295732.`,
};
