export default {
  'deposit.title': 'Capital deposit with Sapheer',
  'deposit.description': ` allows you to deposit your company's capital to finalize the registration of your company`,
  'deposit.first.subtitle': 'Required documents',
  'deposit.first.paragraph': 'You will have to provide for each partner:',
  'deposit.first.listItem1': 'A good quality identity document ',
  'deposit.first.listItem2': 'Proof of residence ',
  'deposit.second.subtitle': 'Status of your company',
  'deposit.second.paragraph': `You do not need your final statutes to continue your registration. The list of partners must, however, be definitive.`,
  'deposit.first.link': '(more information)',
  'deposit.second.link':
    'specific mentions of the capital deposit with Sapheer',
  'deposit.status.title': 'Modification of the statutes of your company',
  'deposit.status.p1':
    'In order for us to provide your certificate of deposit of funds, it is necessary that you add the following information to the statutes of your company:',
  'deposit.status.p2':
    'In the article "Contributions", the following paragraph:',
  'deposit.status.p3':
    '"The funds corresponding to the cash contributions were deposited by Sapheer, SPARK SAS company, duly mandated for this purpose by each of the partners, on the account opened in the name of the Company in formation with Study of Master Quentin FOUREZ - Notary at the 1 Place Maréchal Gallieni - 27500 PONT-AUDEMER, as appears from the certificate drawn up by the notary depositary of the funds, on presentation in particular of the state of the subscriptions mentioning the amount paid by the partners. statutes is certified sincere and true by the legal representative of the company. "',
  'deposit.status.p4':
    'In the appendix mentioning the state of the acts performed on behalf of the company in formation before the signing of the statutes:',
  'deposit.status.p5': 'Deposit of the share capital with a notarial study.',
  'deposit.status.p6':
    'Opening of a transit account in their name with SPARK SAS (Spaheer), an authorized payment institution with the ACPR',
  'deposit.follow.instruction': `Follow the instruction, it's easy !`,
  'deposit.step.one': `Step 1`,
  'deposit.step.two': `Step 2`,
  'deposit.step.three': `Step 3`,
  'step.one.text': `The funds corresponding to the cash contributions were deposited by Sapheer, SPARK SAS, duly authorized for this purpose by each of the partners, in the account opened in the name of the Company under formation with the law firm of Maître Quentin FOUREZ - Notary at 1 Place Maréchal Gallieni - 27500 PONT-AUDEMER, as it results from the certificate established by the notary depositary of the funds, on presentation in particular of the statement of subscriptions mentioning the sum paid by the partners. The statement of subscriptions attached to the present articles of association is certified true and genuine by the legal representative of the company.`,
  'step.two.text': `Deposit of the share capital with a notary's office. Opening of a transit account in their names with SPARK SAS (Sapheer), a payment institution approved by the ACPR.`,
  'deposit.step.one.first.paragraph': `Insert this `,
  'deposit.step.one.link': `text `,
  'deposit.step.one.second.paragraph': `in your statutes to the article "Contributions" by copying and pasting.`,
  'deposit.step.two.first.paragraph': `Insert this `,
  'deposit.step.two.link': `text `,
  'deposit.step.two.second.paragraph': `in the appendix mentioning the status of the acts performed on behalf of the company in formation prior to signature of the statuses by copying and pasting.`,
  'deposit.step.three.paragraph': `For each partner, you must provide identification and proof of address for the following the subscription.`,
  'step.one.dialog.title': `Text for the article "Contributions".`,
  'step.two.dialog.title': `Text intended for the status of acts accomplished`
};
